/*:root {
  --secondary: #00C134;
  --primarycolor : #870000;
  --primarysecondcolor : #121826;
  --mainfontcolor : #00008B;
  --bgcolor : #E4E4E4;
}*/

::-webkit-scrollbar {
    display: none; /*Safari and Chrome*/
}


::selection { background: transparent; }
::-moz-selection { background: transparent; }
a:-moz-focusring {
    color: transparent;
	text-shadow: 0 0 0 #000;
	/* border : none !important; */
}

input[type="submit"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/* input[type="radio"] {
    height:16px !important;
    -moz-appearance: radio !important;
    -webkit-appearance: radio !important;
    appearance: radio !important;
} */

/* Your App styles here */
a {
	text-decoration: none;
}


.navbar-inner {
	position: unset;
}

.block-title {
	overflow: unset;
}

.page {
	overflow: auto;
}

.App-header {
	/*background:var(--primarycolor) !important;*/
	/*background: #870000;*/

	/* background: var(--bgcolor); */
	/* background-color: #00ccff; */

	/* background-color: #663202; */
	background-color: #008000;
	
	/*background: var(--primarycolor) !important;*/
	filter: drop-shadow(0pt 3pt 6pt #00000029);
	/*background-image: linear-gradient( var(--primarycolor)  , var(--primarycolor) 35%, var(--primarysecondcolor) 100%);*/
	/*background-image: linear-gradient(90deg, var(--primarysecondcolor) 35%, var(--primarycolor) 100%);*/
}

.page-content, .App-header-inner , .App-footer-inner, .semi-page-content {
	margin: 0 auto;
	/*width: 576px;*/
	width: 100%;
	max-width: 480px;
	/* width: 499px; */

}

.App-header-inner {
	/* padding: 8px 0px; */
	padding-top: 8px;
	display: flex;
	display: -ms-flexbox;
	height: 60px;
}

/*.vdisplay-middle {
	display : inline-block;
	height: 100%;
	vertical-align: middle;
}
*/
.parent {
  align-items: center; /* For vertical align */
  display: flex;
  height: 90%;
  justify-content: center;
  /* justify-content: center; <- for horizontal align */
 /* width: 250px;*/
}

.parent-ui {
  align-items: center; /* For vertical align */
  display: flex;
  height: 90%;
  /* justify-content: center; <- for horizontal align */
 /* width: 250px;*/
}


.page-content {

/*	height: 90% !important;*/

	overflow: visible;
	/*overflow-y: scroll !important;*/

/*	min-height: 500px;*/
	min-height: 100vh;
	padding-bottom: 40px;
	/*background: #fff;*/
	/*background: #fdfcc0;*/
	/* background: url('/img/middle-ellipse.png'); */

}

.page-contest {
  padding: 0px 0px 0px 30px;
}

.semi-page-content  {
	min-height: 540px;
	/*min-height: 450px;*/
}



.list-content {
	overflow: visible;
	/*overflow-y: scroll !important;*/
	height: 90% !important;
	/* min-height: 500px; */
	background: #fff;
}



.profile-circle {
	height: 30px;
	vertical-align: middle;
	border-radius: 30px;
}

.list-img-circle {
	height: 40px;
	vertical-align: middle;
	border-radius: 40px;
	margin: 1px;
	padding-right: 4px;
}

/* .list-img-circle {
    height: 18px;
    vertical-align: middle;
    border-radius: 18px;
    margin: 10px;
} */



.list-header span {
	margin-left: 5px;
	color: #000;
	font-weight: bold;
	text-decoration: none;
	/* display: block; */
	cursor: default;
}

.img-footer-circle {
	height: 25px;
    vertical-align: middle;
    border-radius: 25px;
    margin-bottom: 5px;
}


.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}



.navbar {
	background: #00C134 !important;
}

.block-title {
    margin: 10px 10px 1px !important;
}

.md .navbar:after {
	background: none;
}


.md .list .item-content {
	padding-left: 0px;
}


.App-footer {
	position: fixed;
    /* left: 0; */
    /*bottom: 0;*/
    width: 100%;
    /*height: 70px;*/
    display: flex;
    align-items: center;
 /*   padding-top: 10px;
    padding-bottom: 10px;*/
    /* background: var(--primarysecondcolor); */
	/* background-color: #0d21a1; */
	/* background-color: #663202; */
	background-color: #008000;
    /*max-height: 40px;*/
    /*min-height: 70px;*/
    min-height: 60px;
    /* box-sizing: border-box; */
    /* background:#00C134 !important; */
    /* display: block; */
    /* padding: 5px; */
}

.App-footer span {
	font-size: 9px;
	display: block;
}

.swiper-pagination-bullet-active {
	background: #00C134 !important;
}



.mde-header ul.mde-header-group li.mde-header-item {
	margin: 2px !important;
}
/* .mde-header ul.mde-header-group li.mde-header-item {
	margin: 0px 1px;
} */

/* @media (max-width: 768px) {

	@media only screen and (orientation:portrait)
    {
        .chlivechat
        {
            display: block;
        }
    }

    @media only screen and (orientation:landscape)
    {
        .chlivechat
        {
            display: none !important;
        }
    }

} */

.customModal {
	padding: 0px !important;
	background: transparent !important;
}

.customModal button {
	z-index: 10;
	background : #fff !important;
}

/*.am-list-body {
	background: #fff;
}
*/
.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
	padding-left: 30px;
}
.styles_faq-row-wrapper__3vA1D {
	background: transparent !important;
}

@media (max-width:575px){

	.phone-prefix {
		width: auto !important;
	}

	.swiper-video-container1 {
		padding-left: 8px;
		padding-right: 8px;
	}
	/* background: rgb(255,175,64);
	background: linear-gradient(90deg, rgba(255,175,64,1) 0%, rgba(255,255,129,1) 50%, rgba(239,142,25,1) 100%);

	body:before {
		content: ' ';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(100deg, rgba(255,175,64,1) 0%, rgba(255,255,129,1) 50%, rgba(239,142,25,1) 100%);
		z-index: -1;
	} */

	body {
		/* background: rgb(255,175,64);
  		background: url("/img/athapyapalace_mobile.png") no-repeat fixed bottom, linear-gradient(90deg, rgba(255,175,64,1) 0%, rgba(255,255,129,1) 50%, rgba(239,142,25,1) 100%); */
		background: #2ae85d;
	}
	
	.mde-header .svg-icon {
		font-size: small;
	}

	.mde-header ul.mde-header-group {
		padding: 0px !important;
	}



/*	.App-footer-inner, .App-header-inner, .page-content, .semi-page-content, .list-content,.navbar-inner {
		width: 97% !important;
	}*/

	.navbar-inner {
		padding-right: 15px;
	}

	.page {
		overflow: unset !important;
	}

	.page-content {
		overflow: auto;
	}

	.logo {
		padding: 0px 0px 0px 8px;
	}

	.logo a {
		padding-left: 10px;
	}

	.main-content {
		padding: 0px 5px 0px 8px;
	}

	.page-contest {
		padding: 0px 0px 0px 20px;
	}

	/* .swiper-paragraph {
		margin-top: 20% !important;
	} */
	.calendar-color-box .left {
		width: 180px;
	}

	.live-detail-header {
		height: unset;
	}

	.live-box, .live-box.active {
		width: 90px !important;
	}

	.league-list-header span {
		margin-left: 10px !important;
	}

	.download-apk-here a {
		margin-right: 20px !important;
	}

	.App-header-inner {
		/* padding: 10px 0px !important; */
		padding-top: 8px;
	}

	/* .grids {
		padding-top : 30px !important;
	} */

	.grid-s-100 {
		width: 100% !important;
	}

	.list-featured-p {
		margin-bottom: 0;
		padding-top: 3px;
		line-height: 23px;
		font-size: 17px;
		font-size: 1em;
		padding-bottom: 3px;
	}

	/* .media {
		padding: 10px;
	} */

	/* .media .media-body {
		margin-left: 50px;
	} */

	.iframe-video {
		height: 200px !important;				
	}

	.top-btn {
		margin-top : 20px;
		margin-right : 5px;
	}

	.homeiconlist .thumbnail-icon {
		height: 100px !important;
		min-width: 110px;
	}

	.switch {
	  width: 80px;
	}

	.logo b {
		padding-left: 20px;

	}

/*	.homeiconlist {
	  max-width: 470px;
	  margin : 0 auto;
	}
	*/
}



.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
	padding-left: 10px;
}

.faq-row .row-content-text {
      padding-left: 10px !important;
}

/* .dot {
  margin-top:10%;
  height: 25px;
  position: relative;
  width: 25px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
} */
