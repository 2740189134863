.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(70, 111, 161);
  height: 56px;
}

.toolbar_navigator {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.toolbar_logo {
  margin-left: 1rem;
}

.toolbar_logo a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: rgb(223, 75, 30);
}

/*@media screen and (max-width: 760px) {
  .toolbar_navigation-items {
    display: none;
  }
}*/

/*@media screen and (min-width: 759px) {
  .toggle-btn {
    display: none;
  }

  .toolbar_logo {
    margin-left: 0;
  }
}
*/