.color-text {
    color : #121826;
}

.color-white {
    color : #fff;
}

.color-gray {
    color : #e2e2e2;
}

.color-slight-gray {
    background : #f2f3f5;
}

.color-dark-yellow {
    color : yellow;
}
.color-dark-gray {
    color : rgb(136, 136, 136);
}

.color-dark-white {
    color : #a8a8a8;
}

.color-milk {
    color : #00f99b;
}

.color-black {
    color : #000 !important;
}

.color-dark-blue {
    color : #100a4a !important;
}

.color-blue {
    color: hsl(214, 89%, 52%);
}


.color-red {
    color : #FF0000;
}

.color-dark-red {
    color : #870000;
}

.color-yellow {
    color : rgb(199, 154, 38);
}

.color-gold {
    color : #FFDF00 !important;
}

.color-orange {
    color : #FFA500;
}

.color-theme-gold {
    /* color : #FFE200; */
    color : #000;

}
.color-theme-divone {
    color : #FFE200;
    font-size: 35px;
}
.color-theme-divtwo {
    color : #FFE200;
    font-size: 35px;
}

.color-green {
    /* color : #00FF00; */
    color : rgb(5, 117, 5);
}

.color-green-light {
    color : #00C134;
}

.color-gray {
    color: #e2e2e2;
}

.bg-second {
    background:  #006290;
}

.bg-green {
    background : #00FF00;
}

.bg-yellow {
    background : rgb(199, 154, 38);
}

.bg-theme-gold {
    background : #FFE200;
}

.bg-red {
    background : #FF0000;
}

.bg-dark-red {
    background : #870000;
}

.bg-green-light {
    background : #00C134;
}

.bg-white {
    background: #fff;
}

.bg-slight-gray {
    background : #f2f3f5;
}

.bg-light-gray {
    background: #f6f6f6;
}

.bg-gray {
    background: #e2e2e2;
}

.bg-blue {
    background: rgb(48, 70, 228);
}

.bg-black {
    background : #000 !important;
}

.bg-light-purple {
    background : var(--primarythirdcolor);
}

.bg-image {
    background: url('/img/bg-pattern3.jpg');
}

.bg-noise {
    /* background: url('/img/noise.png'); */
    /* background-position: center;
    background-size: cover; */
    /* background: linear-gradient(107.77deg, #684FFF 15.95%, #B871FE 100%); */
    background: linear-gradient(107.77deg,#0bccff 65.95%,#1d36d6);
    /* border-radius: 50px; */
/*    mix-blend-mode: overlay;
    opacity: 0.08;*/
}

.btn-blue {
    color: #fff !important;
    background: rgb(48, 70, 228) !important;
}

.bg-contact{
    background-color:
}

/* content */
.content__box{
    display:flex;
    justify-content:space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    padding:10px;
    /* background-color:#ffff; */
}
.phone__1{
    margin-left:30px;
    color:blue;
    font-weight: 900;

}
.phone__2{
    margin-right:30px;


}

.color-version{
    font-weight: 600;
    font-size: large;
    text-align:center;
    margin-top:10px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}