.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
   background: rgba(0, 0, 0, 0.3); 
/*  background: rgba(201, 52, 52, 0.3);
  rgba(157, 145, 145, 0.3)*/
  z-index: 100;
}
