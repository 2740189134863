.outerContainer {
  display: block;
  justify-content: center;
  z-index: 100;
  /* border: 3px solid #e2e2e2; */
  /* align-items: center; */
  /* height: 50vh; */
  /* background-color: #1A1A1D; */
}

.container-chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  /* min-height: 400px; */
  min-height: 300px;
  /* height: 60%; */
  /* width: 35%; */
}

.container-full-chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  min-height: 520px;
  border: 3px solid #e2e2e2;
}

@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {
    /* height: 100%; */
    /* height: 50vh; */
    height: auto;
  }

  .container {
    width: 100%;
    height: 100%;
  }

  .hls-player{
		height: 230px !important;
	}
}

@media (min-width: 480px) and (max-width: 1200px) {
  .container {
    width: 60%;
  }

  .outerContainer {
    /* height: 100%; */
    height: auto;
    /* height: 50vh; */
  }
  
  .hls-player{
		height: 230px !important;
	}
}
